import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setModalType, updateComic } from '../../redux/actions';
import ComicsRow from "./comicsRow";

const ComicsTitle = ({ allToggled, comics, odd = true, release_date, title, year_group }) => {
    const dispatch = useDispatch();
    const lastComic = comics[comics.length - 1];
    const [rowsToggled, setRowsToggled] = useState(false);

    console.log(lastComic)
    console.log((parseInt(lastComic.issue_no, 10) + 1).toString())

    useEffect(() => {
        setRowsToggled(allToggled)
    }, [allToggled]);

    return <div className="comics-section">
        <div className={`tr title-row ${odd ? 'odd' : 'even'}`}>
            <div className="td title mobile">
                <i
                    className="icofont-plus-circle"
                    onClick={() => dispatch(setModalType({
                        id: null,
                        comic_title: comics[0].title,
                        title: 'Add Comic',
                        type: 'add',
                        year_group
                    }))}
                />
                <i
                    className="icofont-ui-next"
                    onClick={() => {
                        dispatch(updateComic(
                            false, {
                                ...lastComic,
                                issue_no: (parseInt(lastComic.issue_no, 10) + 1).toString()
                            },
                            null,
                            new Date().getFullYear()
                        ));
                    }}
                />{' '}
                {title}
            </div>
            <div className="td issue_no number mobile"></div>
            <div className="td mobile" width="40"><i className="icofont-circled-down" onClick={() => setRowsToggled(!rowsToggled)}></i></div>
        </div>
        {!rowsToggled ? <div className="comics-set">{comics.map((c, i) =>
            <ComicsRow
                c={c}
                key={`${comics.title}-${i}`}
                odd={i % 2 === 0}
                year_group={year_group}
            />
        )}</div> : ''}
    </div>;
};

export default ComicsTitle;
