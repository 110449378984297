import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrands, fetchRuns, searchComics } from '../../../redux/actions';
import './index.css';

const formDataDefault = {
    brand: '',
    comic_status: '1',
    listOrder: false,
    readingOrder: false,
    titleExact: false,
    series: '',
    seriesName: null,
    title: ''
};

const SearchModal = () => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(formDataDefault);
    const brands = useSelector((state) => state.brands);
    const runs = useSelector((state) => state.runs);

    useEffect(() => {
        // on load check for brand/series
        if (!brands.length) {
            dispatch(fetchBrands());
        }

        if (!runs.length) {
            dispatch(fetchRuns());
        }
    }, []);

    return <>
        <section className="modal-content">
            <fieldset className="flex two-thirds">
                <label>
                    Search By Title
                    <input
                        onChange={e => {
                            setFormData({
                                ...formData,
                                title: e.target.value
                            })
                        }}
                        placeholder="Batman"
                        type="text"
                        value={formData.title}
                    />
                </label>
                <div className="flex">
                    <br />
                    <label style={{ transform: 'translateY(30px)' }}>
                        <input
                            checked={formData.titleExact}
                            onChange={e => {
                                setFormData({
                                    ...formData,
                                    titleExact: e.target.checked
                                })
                            }}
                            type="checkbox"
                        />
                        <span className="checkable">Exact?</span>
                    </label>
                </div>
            </fieldset>
            <span> - OR - </span>
            <fieldset className="flex">
                <fieldset className="flex">
                    <label>
                        Filter By Publisher
                        <select
                            onChange={e => {
                                setFormData({
                                    ...formData,
                                    brand: e.target.value
                                })
                            }}
                            value={formData.brand}
                        >
                            <option>Any</option>
                            {brands.map(b => <option key={b.id} value={b.id}>{b.name}</option>)}
                        </select>
                    </label>
                </fieldset>
            </fieldset>
            <span> - OR - </span>
            <fieldset className="flex">
                <label>
                    Filter By Series
                    <select
                        onChange={e => {
                            setFormData({
                                ...formData,
                                series: e.target.value,
                                seriesName: runs.filter(r => r.id === e.target.value)[0].title
                            })
                        }}
                        value={formData.series}
                    >
                        <option>Select</option>
                        {runs.map(r => {
                            return r.title ? <option key={r.id} value={r.id}>{r.title}</option> : null
                        })}
                    </select>
                </label>
                <label style={{ transform: 'translateY(-12px)' }}>
                    <input
                        checked={formData.readingOrder}
                        disabled={formData.listOrder}
                        onChange={e => {
                            setFormData({
                                ...formData,
                                readingOrder: e.target.checked
                            })
                        }}
                        type="checkbox"
                    />
                    <span className={`checkable ${formData.listOrder && 'disabled'}`}>Show in reading order?</span>
                </label>
                <label style={{ transform: 'translateY(-32px)' }}>
                <input
                    checked={formData.listOrder}
                    disabled={formData.readingOrder}
                    onChange={e => {
                        setFormData({
                            ...formData,
                            listOrder: e.target.checked
                        })
                    }}
                    type="checkbox"
                />
                <span className={`checkable ${formData.readingOrder && 'disabled'}`}>Show as list</span>
            </label>
            </fieldset>
        </section>
        <footer>
            <button className="pull-right button" onClick={() => dispatch(searchComics(formData))}>Search</button>
        </footer>
    </>
};

export default SearchModal;