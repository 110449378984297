import React from "react";
import { useDispatch } from "react-redux";
import { setModalType } from '../../redux/actions';
import ModalForm from "./contents/form";
import AToZSearch from "./contents/letters";
import SearchModal from "./contents/search";
import ViewModal from "./contents/view";
import './index.css';

const Modal = ({ modal }) => {
    const dispatch = useDispatch();

    return <div className="modal-overlay">
        <div className="card search-modal">
            <article>
                <header>
                    <h3 id="modal-title">{modal.title}</h3>
                    <label className="close" onClick={() => dispatch(setModalType({}))}>&times;</label>
                </header>
               {modal.type === 'add' || modal.type === 'update' ? <ModalForm modal={modal} /> : ''}
               {modal.type === 'aToZ' && <AToZSearch />}
               {modal.type === 'search' && <SearchModal />}
               {modal.type === 'view' && <ViewModal modal={modal} />}
            </article>
        </div>
    </div>;
};

export default Modal;