import React from "react";
import ReactDOM from "react-dom";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import MainComponent from "./MainComponent";
import counter from "./redux/reducer";

const store = createStore(counter, applyMiddleware(thunk));
const rootEl = document.getElementById("root");

const render = () =>
  ReactDOM.render(
    <Provider store={store}>
      <MainComponent />
    </Provider>,
    rootEl
  );

render();
store.subscribe(render);
