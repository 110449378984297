import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalType } from '../../redux/actions';
import './list.css';

const formData = {
    brand: '',
    comic_status: '',
    id: '',
    issue_no: '',
    purchase_price: '',
    release_date: '',
    reprint: '',
    series: '',
    title: '',
    variant_cover: '',
    volume: ''
};

const ListView = () => {
    const comics = useSelector((state) => state.comics_all.comics);
    const seriesName = useSelector((state) => state.seriesName);
    let odd = 0;
    const dispatch = useDispatch();
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
    });
    return comics ? <section className="comics-list-view">
        {seriesName ? <h2 className="series-title">{seriesName}</h2> : ''}
        {Object.keys(comics).map(title => Object.keys(comics[title]).map((t, i) => {
            const list = comics[title][t];
            return list[0].title && list.length > 0 ?
                <div key={`${t}-${i}`}>{list.map((c, i) => {
                    odd = odd + 1;
                    const stripe = odd % 2 === 0 ? 'odd' : 'even';
                    return <div
                        className={`tr wishlist ${stripe}`}
                        key={`${c.title}-${i}`}
                        id={c.id}
                    >
                        <div className="td mobile">
                            <i
                                className={`icofont-check${c.comic_status === '1' ? '-circled' : ''}`}
                                onClick={() => dispatch(updateComic(true, {
                                    ...formData,
                                    ...c,
                                    comic_status: '1',
                                }, c.id, year_group))}
                            >
                            </i>
                        </div>
                        <div className="td title mobile">{c.title}<span></span></div>
                        <div className="td issue_no number mobile">{c.issue_no}</div>
                        <div className="td volume number">{c.volume || '-'}</div>
                        <div className="td release_date number">{c.release_date}</div>
                        <div className="td purchase_price number">{formatter.format(c.purchase_price)}</div>
                        <div className="td mobile"><i
                            className="icofont-pencil-alt-5"
                            onClick={() => dispatch(setModalType({
                                id: c.id,
                                comic_title: c.title,
                                title: 'Update Comic',
                                type: 'update',
                                year_group: t
                            }))}
                        >
                        </i></div>
                    </div>
                })}</div> : ''
        }))}
    </section> : '';
};

export default ListView;
