import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { searchComicsAlpha } from '../../../redux/actions';
import './index.css';

const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

const AToZSearch = () => {
    const dispatch = useDispatch();
    return <section className="modal-content">
        <div className="a-z-buttons">
            {alphabet.map(letter =>
                <button
                    className="alpha-button"
                    key={`search-${letter}`}
                    onClick={() => {
                        dispatch(searchComicsAlpha(letter));
                    }}>
                    {letter}
                </button>
            )}
        </div>
    </section>
};

export default AToZSearch;