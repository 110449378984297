import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalType, updateComic } from '../../redux/actions';
import './list.css';

const formData = {
    brand: '',
    comic_status: '',
    id: '',
    issue_no: '',
    purchase_price: '',
    release_date: '',
    reprint: '',
    series: '',
    title: '',
    variant_cover: '',
    volume: ''
};

const ComicsRow = ({ c, odd = false, year_group }) => {
    const dispatch = useDispatch();
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
    });

    return <div id={c.id} className={`tr wishlist ${odd % 2 ? 'odd' : 'even'}`}>
        <div className="td mobile">
            <i
                className={`icofont-check${c.comic_status === '1' ? '-circled' : ''}`}
                onClick={() => dispatch(updateComic(true, {
                    ...formData,
                    ...c,
                    comic_status: '1',
                }, c.id, year_group))}
            >
            </i>
        </div>
        <div className="td title mobile">{c.title}<span></span></div>
        <div className="td issue_no number mobile">{c.issue_no}</div>
        <div className="td volume number">{c.volume || '-'}</div>
        <div className="td release_date number">{c.release_date}</div>
        <div className="td purchase_price number">{formatter.format(c.purchase_price)}</div>
        <div className="td mobile"><i
            className="icofont-pencil-alt-5"
            onClick={() => dispatch(setModalType({
                id: c.id,
                comic_title: c.title,
                title: 'Update Comic',
                type: 'update',
                year_group
            }))}
        >
        </i></div>
    </div>;
};

const ComicsTitle = ({ comics, odd = true, release_date, title, year_group }) => {
    const dispatch = useDispatch();
    const [rowsToggled, setRowsToggled] = useState(true);

    return <div className="comics-section">
        <div className={`tr wishlist title-row ${odd ? 'odd' : 'even'}`}>
            <div className="td title mobile">
                <i
                    className="icofont-plus-circle"
                    onClick={() => dispatch(setModalType({
                        id: null,
                        comic_title: comics[0].title,
                        title: 'Add Comic',
                        type: 'add',
                        year_group
                    }))}
                />{' '}
            </div>
            <div className="td title mobile">{title}<span></span></div>
            <div className="td issue_no number mobile"></div>
            <div className="td volume number"></div>
            <div className="td release_date number">{release_date}</div>
            <div className="td purchase_price number"></div>
            <div className="td mobile" width="40"><i className="icofont-circled-down" onClick={() => setRowsToggled(!rowsToggled)}></i></div>
        </div>
        {!rowsToggled ? <div className="comics-set">{comics.map((c, i) =>
            <ComicsRow
                c={c}
                key={`${comics.title}-${i}`}
                odd={i % 2 === 0}
                year_group={year_group}
            />
        )}</div> : ''}
    </div>;
};

const Wishlist = () => {
    let odd = true;
    const comics = useSelector((state) => state.comics_wishlist.comics);
    return  comics ? <section>
            {Object.keys(comics).map(title => Object.keys(comics[title]).map((t, i) => {
                const list = comics[title][t];
                odd = list[0].title && list.length > 0 ? !odd : odd;

                return list[0].title && list.length > 0 ?
                    <ComicsTitle
                        comics={list}
                        key={`${list[0].title}-set-${t}`}
                        odd={odd}
                        release_date={t}
                        title={`${list[0].title} (${t})`}
                        year_group={t}
                    /> : ''
            })
            )}
        </section> : ''
    
};

export default Wishlist;
