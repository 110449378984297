import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalType } from '../../redux/actions';
import './list.css';

const ComicsRow = ({ c, odd = false }) => {
    const dispatch = useDispatch();

    return <div id={c.id} className={`tr wishlist ${odd % 2 ? 'odd' : 'even'}`}>
        <div className="td mobile">
            <i
                className={`icofont-check${c.comic_status === '1' ? '-circled' : ''}`}
            />
            <b>{c.series_order}.</b>
        </div>
        <div
            className="td title mobile"
            onClick={() => dispatch(setModalType({
                id: c.id,
                comic_title: c.title,
                title: 'View Comic',
                type: 'view',
                year_group: c.release_date
            }))}
        >
            {c.title} - {c.issue_no}
        </div>
        <div
        className="td issue_no number mobile"
        onClick={() => dispatch(setModalType({
            id: c.id,
            comic_title: c.title,
            title: 'View Comic',
            type: 'view',
            year_group: c.release_date
        }))}
        ><i className="icofont-arrow-up" /></div>
    </div>;
};

const Series = () => {
    const comics = useSelector((state) => state.comics_series);
    const seriesName = useSelector((state) => state.seriesName);
        
    return (
        <section>
        {seriesName ? <h2 className="series-title">{seriesName}</h2> : ''}
            <div className="comics-section series-view">
                {comics.map((comic, i) => <ComicsRow
                    c={comic}
                    key={`${comic.title}-${i}`}
                    odd={i % 2 === 0}
                />
                )}
            </div>
        </section>
    );
};

export default Series;
