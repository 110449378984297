import * as constants from "./constants";
const brands = "api/brands/get_brands.php";
const brandCreate = "api/brands/create.php";
const collected = "api/comics/collected.php";
const create = "api/comics/create.php";
const getTitles = "api/comics/get_titles.php";
const runCreate = "api/runs/create.php";
const runs = "api/runs/get_runs.php";
const search_series = "api/comics/search_series.php";
const single = "api/comics/single_read.php";
const update = "api/comics/update.php";
const wish = "api/comics/wishlist.php";
const env_url =  'https://comics.melissakate.com';

export const setModalType = modalType => ({
  modalType,
  type: constants.SET_MODAL_TYPE
});

export const setToast = text => dispatch => {
  if (text) {
    setTimeout(() => {
      dispatch({
        text: '',
        type: constants.SET_TOAST
      })
    }, 2000);
  }
  dispatch({
    text,
    type: constants.SET_TOAST
  })
};

export const createBrand = (name) => dispatch => {
  fetch(`${env_url}/${brandCreate}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    body: JSON.stringify({
      name: name.trim()
    })
  })
    .then(response => response.text())
    .then(data => {
      if (data) {
        dispatch(fetchBrands());
        dispatch(setToast('Brand Created'))
      }
    })
    .catch(e => dispatch(setToast(`Oops! Unable to create at this time.`)));
};

export const createRun = (title) => dispatch => {
  fetch(`${env_url}/${runCreate}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    body: JSON.stringify({
      title: title.trim()
    })
  })
    .then(response => response.text())
    .then(data => {
      if (data) {
        dispatch(fetchRuns());
        dispatch(setToast('Series Created'))
      }
    })
    .catch(e => dispatch(setToast(`Oops! Unable to create at this time.`)));
};

export const deleteComic = (id) => dispatch => {
  fetch(`${env_url}/api/comics/delete.php`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    body: JSON.stringify({
      id
    })
  })
    .then(response => response.text())
    .then(() => {
      dispatch(fetchComics())
      dispatch(setModalType({}))
    })
    .catch(() => {
      dispatch(setToast("No Comics Found"));
    });
};

export const fetchBrands = () => dispatch =>
  fetch(`${env_url}/${brands}`)
    .then(response => response.text())
    .then(data => JSON.parse(data))
    .then((data) =>
      dispatch({
        brands: data,
        type: constants.FETCH_BRANDS
      })
    )
    .catch(() => {
      dispatch(setToast("No Brands Found"));
    });

export const fetchComic = (id) => dispatch => fetch(`${env_url}/${single}?id=${id}`)
  .then(response => response.text())
  .then(data => JSON.parse(data))
  .then((data) => {
    dispatch({
      comic: data,
      type: constants.FETCH_COMIC,
      view: 'all'
    });
  })
  .catch(() => {
    dispatch(setToast("No Comics Found"));
  });

  export const fetchComics = () => dispatch => fetch(`${env_url}/${collected}`)
  .then(response => response.text())
  .then(data => JSON.parse(data))
  .then((data) => {
    dispatch({
      comics: data,
      type: constants.FETCH_COMICS,
      view: 'all'
    });
  })
  .catch(() => {
    dispatch(setToast("No Comics Found"));
  });

export const fetchRuns = () => dispatch => {
  fetch(`${env_url}/${runs}`)
    .then(response => response.text())
    .then(data => JSON.parse(data))
    .then((data) =>
      dispatch({
        runs: data,
        type: constants.FETCH_RUNS
      })
    )
    .catch(() => {
      dispatch(setToast("No Runs Found"));
    });
}

export const fetchTitles = () => dispatch => {
  fetch(`${env_url}/${getTitles}`)
    .then(response => response.text())
    .then(data => JSON.parse(data))
    .then((data) =>
      dispatch({
        titles: data,
        type: constants.FETCH_TITLES
      })
    )
    .catch(() => {
      dispatch(setToast("No Titles Found"));
    });
}

export const fetchWishlist = () => dispatch =>
  fetch(`${env_url}/${wish}`)
    .then(response => response.text())
    .then(data => JSON.parse(data))
    .then((data) => {
      dispatch({
        comics: data,
        type: constants.FETCH_WISHLIST,
        view: 'wishlist'
      });
    })
    .catch(() => {
      dispatch(setToast("No Comics Found"));
    });

export const refetchPrevList = (vals) => dispatch => {
  // do whatever we did last
  dispatch(vals.fetchType(vals.params))
}

export const searchComicsAlpha = letter => dispatch => fetch(
  `${env_url}/api/comics/search.php?type=title&searchTerm=${letter}&first=true&status=1`
)
  .then(response => response.text())
  .then(data => {
    if (data.length > 0) {
      const comics = JSON.parse(data);
      if (!comics.message) {
        dispatch({
          comics,
          type: constants.FETCH_COMICS,
          view: 'alpha'
        });
        dispatch(setModalType({}));
      }
      if (comics.message) {
        dispatch(setToast(`No comics found beginning with ${letter}`));
      }
    }
  })
  .catch(() => {
    dispatch(setToast(`No comics found beginning with ${letter}`));
  });

export const searchComics = formData => dispatch => {
  const { brand, comic_status, listOrder, readingOrder, series, seriesName, title, titleExact } = formData;

  let url = 'api/comics/search.php';

  let params = `searchTerm=${title.trim()}&exact=${titleExact}`;
  let searchType = 'title';

  if (title !== '') {
    params = `searchTerm=${title.trim()}&exact=${titleExact}`;
    searchType = "title";
  }

  if (series !== '') {
    params = `searchTerm=${series}&exact=true`;
    searchType = "series";
  }

  if (brand !== '') {
    params = `searchTerm=${brand}&exact=true`;
    searchType = "brand";
  }

  if (readingOrder) url = search_series;

  fetch(
    `${env_url}/${url}?type=${searchType}&${params}&status=${comic_status}`
  )
    .then(response => response.text())
    .then(data => JSON.parse(data))
    .then(data => {
      if (data.message) {
        dispatch(setToast(`No comics found`));
      }
      if (data.body && !readingOrder) {
        dispatch({
          comics: data,
          seriesName: seriesName,
          type: constants.FETCH_COMICS,
          view: listOrder ? 'list' : 'all'
        });
      }
      if (data.body && readingOrder) {
        dispatch({
          comics: data.body,
          seriesName: seriesName,
          type: constants.SET_SERIES_LIST,
          view: listOrder ? 'list' : 'series'
        });
      }
        dispatch(setModalType({}));
    })
    .catch((e) => {
      dispatch(setToast(e, `No comics found`));
    });
};

export const updateComic = (editing = false, formData, id, yearGroup) => dispatch => {
  const endpoint = editing ? update : create;
  const { title, issue_no, release_date, purchase_price, series, series_order, volume, collector, brand, comic_status, reprint, variant_cover } = formData;

  fetch(`${env_url}/${endpoint}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    body: JSON.stringify({
      id,
      title: title.trim(),
      issue_no,
      release_date,
      comic_status,
      purchase_price,
      series,
      series_order,
      volume,
      collector,
      reprint,
      variant_cover,
      brand
    })
  })
    .then(response =>  response.text())
    .then(data => {
      if (data) {
        // if this is an update replace the data of the comic with the new data
        if (editing) {
          dispatch({
            formData,
            type: constants.UPDATE_COMIC,
            yearGroup
          });
        } else {
          dispatch({
            formData,
            type: constants.ADD_COMIC,
            yearGroup
          });
        }
        // if this is an add - we will refetch when the modal closes
        dispatch(setToast(`${title} ${issue_no} ${editing ? "updated" : "added"}`));
      }
    })
    .catch(() => dispatch(setToast(`Oops! Unable to ${editing ? "update" : "add"} at this time.`)));
};
