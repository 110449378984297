import React from "react";
import { useSelector } from "react-redux";
import Header from "./components/nav";
import Search from "./components/search";
import ComicsList from "./components/comics/comicsList";
import Modal from "./components/modal";
import Toast from "./components/toast";
import './index.css';

const MainComponent = () => {
  const modal = useSelector((state) => state.modal);
  const toastText = useSelector((state) => state.toast);
  
  return <main className="home">
    <Header />
    <Search />
    <ComicsList />
    {modal.type && <Modal modal={modal} /> }
    {toastText && <Toast toastText={toastText} />}
  </main>
};

export default MainComponent;
