import React from "react";
import { useDispatch } from "react-redux";
import { setToast } from '../../redux/actions';
import './index.css';

const Toast = ({ toastText }) => {
  const dispatch = useDispatch();
  return (
    <div className="toast" onClick={() => dispatch(setToast(''))}>
        {toastText}
    </div>
  );
};

export default Toast;