import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchComics, fetchTitles, setModalType } from "../../redux/actions";
import ComicsTitle from "./comicsTitle";
import ListView from './listView';
import Series from './series';
import Wishlist from "./wishlist";
import './list.css';

const ComicsList = () => {
    const dispatch = useDispatch();
    const titles = useSelector((state) => state.titles);
    const comics = useSelector((state) => state.comics_all.comics);
    const comicCount = useSelector((state) => state.comics_all.itemCount);
    const view = useSelector((state) => state.view);
    const [allToggled, setAllToggled] = useState(true);
    let odd = true;

    useEffect(() => {
        if (titles.length > 0) dispatch(fetchComics());
    }, [titles]);

    useEffect(() => {
        if (titles.length === 0) dispatch(fetchTitles());
    }, []);

    if (view === 'wishlist') return <Wishlist />
    if (view === 'series') return <Series />
    if (view === 'list') return <ListView />

    return (
        <section>
            {comics && comicCount > 0 ? (
                <div className="table table-bordered table-condensed">
                    <div className="thead">
                        <div className="tr">
                            <div className="th title mobile"><i
                                className="icofont-plus-circle"
                                onClick={() => dispatch(setModalType({
                                    id: null,
                                    comic_title: null,
                                    title: 'Add Comic',
                                    type: 'add',
                                    year_group: null
                                }))}
                            />title</div>
                            <div className="th issue_no number mobile"><span>/ Issue</span></div>
                            <div className="th mobile" widdiv="40"><i className="icofont-circled-down" onClick={() => setAllToggled(!allToggled)}></i></div>
                        </div>
                    </div>
                    <div className="tbody">
                        {Object.keys(comics).map(title => Object.keys(comics[title]).map((t, i) => {
                            const list = comics[title][t];
                            odd = list[0].title && list.length > 0 ? !odd : odd;

                            return list[0].title && list.length > 0 ?
                                <ComicsTitle
                                    allToggled={allToggled}
                                    comics={list}
                                    key={`${list[0].title}-set-${t}`}
                                    odd={odd}
                                    release_date={t}
                                    title={`${list[0].title} (${t})`}
                                    year_group={t}
                                /> : ''
                        })
                        )}
                    </div>
                </div>
            ) :
                <section className="comics-list-view comics-list-loading">
                    <span className="loading">
                        <span />
                        <span />
                        <span />
                    </span>
                </section>
            }
        </section>
    );
};

export default ComicsList;
