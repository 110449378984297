import React from "react";
import { useDispatch } from "react-redux";
import { setModalType } from '../../redux/actions';
import './index.css';

const Search = () => {
    const dispatch = useDispatch();
    return <section className="search"><div className="search-lockup search-bar">
        <button className="button pseudo" onClick={() => dispatch(setModalType({ title: 'Search', type: 'search' }))}>
            <i className="icofont-search-2"></i> Search
        </button>
        <button className="button pseudo a-z" onClick={() => dispatch(setModalType({ title: 'Filter by Letter', type: 'aToZ' }))}>A-Z</button>
        <a className="button pseudo" href="/">
            <i className="icofont-redo"></i> Reset
        </a>
    </div></section>;
};

export default Search;


