import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchWishlist } from "../../redux/actions";
import './index.css';

const Header = () => {
    const dispatch = useDispatch();
    const [menuOpen, setMenuOpen] = useState(false);
    return <div className={`${menuOpen ? 'menu-open' : ''}`} style={{ overflow: 'hidden', height: 70 }}>
        <nav className="demo">
            <a href="/" className="brand">
                <h2>MM KV COMICS</h2>
            </a>
            {menuOpen && <div
                className="menu-underlay"
                onClick={() => setMenuOpen(false)}
            />}
            <label className="burger button" onClick={() => setMenuOpen(true)}>menu</label>
            {menuOpen && <div className="menu">
                <span className="link" onClick={() => {
                    dispatch(fetchWishlist());
                    setMenuOpen(false);
                }}>Wish List</span>
                <a href="/logout.php">Logout</a>
            </div>}
        </nav>
    </div>
};

export default Header;
