export const initial = {
  brands: [],
  comics_all: [],
  comics_series: [],
  comics_wishlist: [],
  loading: true,
  modal: {
    id: null,
    title: '',
    type: ''
  },
  prevFetchVals: {},
  runs: [],
  single: {},
  titles: [],
  toast: '',
  view: 'all'
};
