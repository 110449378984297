import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createRun, createBrand, deleteComic, fetchBrands, fetchComic, fetchRuns, updateComic } from "../../../redux/actions";
import './index.css';

const formDataDefault = {
    brand: '',
    comic_status: '1',
    facsimile: false,
    id: '',
    issue_no: '',
    purchase_price: '',
    release_date: '',
    reprint: false,
    series: '',
    series_order: '',
    title: '',
    variant_cover: '',
    volume: ''
};

const ModalForm = ({
    modal
}) => {
    const dispatch = useDispatch();
    const brands = useSelector((state) => state.brands);
    const runs = useSelector((state) => state.runs);
    const single = useSelector((state) => state.single);
    const comics = modal.comic_title ? useSelector((state) => state.comics_all.comics) : null;
    const [formData, setFormData] = useState({
        ...formDataDefault,
        id: modal.id,
    });
    const [newSeriesTitle, setNewSeriesTitle] = useState('');
    const [publisherName, setPublisherName] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [showSeries, setShowSeries] = useState(false);
    const [showPublisher, setShowPublisher] = useState(false);

    const optString = opt => {
        if (opt) return opt.toString();
        return '0';
    }

    useEffect(() => {
        if (modal.id && (!single || single.id !== modal.id)) {
            dispatch(fetchComic(modal.id));
            return;
        }
        if (modal.comic_title && comics) {
            // fetch comic to update
            let comic = null;
            const title_list = modal.year_group ? comics[modal.comic_title.trim()][`${modal.year_group}`] : [];

            if (title_list && title_list.length > 0) {
                comic = {
                    ...title_list[0],
                    comic_status: '1',
                    id: '',
                    issue_no: '',
                    purchase_price: '',
                    reprint: '',
                    series: '',
                    variant_cover: '',
                    volume: ''
                };
            }

            if (comic) {
                setFormData(comic);
            }
        }
    }, [modal.id]);

    useEffect(() => {
        if (modal.id && single && single.id === modal.id) {
            setFormData(single);
        }
    }, [single]);

    useEffect(() => {
        // on load check for brand/series
        if (!brands.length) {
            dispatch(fetchBrands());
        }

        if (!runs.length) {
            dispatch(fetchRuns());
        }
    }, []);

    return <>
        <section className="modal-content">
            <form>
                <fieldset className="flex two-thirds">
                    <fieldset className="flex">
                        <label>
                            <span>Publisher <span className="create-run" onClick={() => setShowPublisher(true)}>Create New</span></span>
                            <span className="hidden">{optString(formData.brand)}</span>
                            <select
                                onChange={e => {
                                    setFormData({
                                        ...formData,
                                        brand: e.target.value.toString()
                                    })
                                }}
                                value={optString(formData.brand)}
                            >
                                <option value="0">Select</option>
                                {brands.map(b => <option key={b.id} value={b.id.toString()}>{b.name}</option>)}
                            </select>
                        </label>
                    </fieldset>

                    <label style={{
                        transform: "translateY(30px)"
                    }}>
                        <input
                            checked={formData.comic_status === "0"}
                            onChange={e => {
                                setFormData({
                                    ...formData,
                                    comic_status: e.target.checked ? "0" : "1"
                                })
                            }}
                            type="checkbox"
                        />
                        <span className="checkable">Wishlist</span>
                    </label>
                </fieldset>
                {showPublisher && <fieldset className="flex">
                    <div className="flex three">
                        <label>Create New Publisher</label>
                        <div><input
                            onChange={e => {
                                setPublisherName(e.target.value)
                            }}
                            placeholder="Marvel"
                            style={{ transform: 'translateX(-12px)' }}
                            type="text"
                            value={publisherName} />
                        </div>
                        <span onClick={() => {
                            dispatch(createBrand(publisherName));
                            setShowPublisher(false);
                        }} className="button">Create</span>
                    </div>
                </fieldset>}
                <fieldset className="flex">
                    <label>
                        Title
                        <input
                            onChange={e => {
                                setFormData({
                                    ...formData,
                                    title: e.target.value
                                })
                            }}
                            placeholder="Batman"
                            type="text"
                            value={formData.title}
                        />
                    </label>
                </fieldset>
                <fieldset className="flex three">
                    <label>
                        Issue
                        <input
                            onChange={e => {
                                setFormData({
                                    ...formData,
                                    issue_no: e.target.value
                                })
                            }}
                            placeholder="999"
                            type="number"
                            value={formData.issue_no}
                        />
                    </label>
                    <label>
                        Volume
                        <input
                            onChange={e => {
                                setFormData({
                                    ...formData,
                                    volume: e.target.value
                                })
                            }}
                            placeholder="1"
                            type="number"
                            value={formData.volume}
                        />
                    </label>
                    <label>
                        Released
                        <input
                            onChange={e => {
                                setFormData({
                                    ...formData,
                                    release_date: e.target.value
                                })
                            }}
                            placeholder="2022"
                            type="number"
                            value={formData.release_date}
                        />
                    </label>
                </fieldset>
                <fieldset className="flex three">
                    <label>
                        Price
                        <input
                            onChange={e => {
                                setFormData({
                                    ...formData,
                                    purchase_price: e.target.value
                                })
                            }}
                            placeholder="3.00"
                            type="number"
                            value={formData.purchase_price}
                        />
                    </label>
                    <label style={{
                        transform: "translateY(30px)"
                    }}>
                        <input
                            checked={formData.reprint === "1"}
                            onChange={e => {
                                setFormData({
                                    ...formData,
                                    reprint: e.target.checked ? "1" : "0"
                                })
                            }}
                            type="checkbox"
                        />
                        <span className="checkable">Reprint</span>
                    </label>
                    <label style={{
                        transform: "translateY(30px)"
                    }}>
                        <input
                            checked={formData.variant_cover === "1"}
                            onChange={e => {
                                setFormData({
                                    ...formData,
                                    variant_cover: e.target.checked ? "1" : "0"
                                })
                            }}
                            type="checkbox"
                        />
                        <span className="checkable">Variant</span>
                    </label>

                </fieldset>
                <fieldset className="flex two-thirds">
                    <label>
                        <span>Series <span className="create-run" onClick={() => setShowSeries(true)}>Create New</span></span>
                        <select
                            onChange={e => {
                                setFormData({
                                    ...formData,
                                    series: e.target.value.toString()
                                })
                            }}
                            value={optString(formData.series)}
                        >
                            <option>Select</option>
                            {runs.map(r => {
                                return r.title ? <option key={r.id} value={r.id.toString()}>{r.title}</option> : null
                            })}
                        </select>
                    </label>
                    <label>
                        Order
                        <input
                            onChange={e => {
                                setFormData({
                                    ...formData,
                                    series_order: e.target.value
                                })
                            }}
                            placeholder="1"
                            type="number"
                            value={formData.series_order}
                        />
                    </label>
                </fieldset>
                {showSeries && <fieldset className="flex">
                    <label>
                        Create New Series
                        <div className="search-lockup">
                            <input
                                onChange={e => {
                                    setNewSeriesTitle(e.target.value)
                                }}
                                placeholder="Year One"
                                style={{ transform: 'translateX(-12px)' }}
                                type="text"
                                value={newSeriesTitle} />
                            <span onClick={() => {
                                dispatch(createRun(newSeriesTitle));
                                setShowSeries(false);
                            }} className="button">Create</span>
                        </div>
                    </label>
                </fieldset>}
            </form>
        </section>
        <footer className={`footer-${modal.type}`}>
            {modal.type === "update" &&
                <label className='button error' onClick={() => setShowDelete(true)}>Delete Comic</label>
            }
            <button className="pull-right" onClick={() => {
                dispatch(updateComic(modal.type === 'update', formData, modal.id, modal.year_group || formData.release_date));
            }}>{modal.type} Comic</button>
        </footer>
        {showDelete && modal.type === "update" ? <div className="modal">
            <article>
                <section className="content">
                    Are you sure you want to delete this comic?
                </section>
                <footer>
                    <div className="button dangerous" onClick={() => {
                        dispatch(deleteComic(modal.id));
                        setShowDelete(false);
                    }}>Yes</div>
                    <label className="button" onClick={() => {
                        setShowDelete(false);
                    }}>
                        Nope
                    </label>
                </footer>
            </article>
        </div> : ''}
    </>;
};

export default ModalForm;
