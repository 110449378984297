import React from "react";
import { useDispatch } from "react-redux";
import { setModalType } from '../../redux/actions';

const ComicsRow = ({ c, odd = false, year_group}) => {
    const dispatch = useDispatch();
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
    });

    return <div id={c.id} className={`tr ${odd % 2 ? 'odd' : 'even'}`}>
        <div
            className="td title mobile"
            onClick={() => dispatch(setModalType({
                id: c.id,
                comic_title: c.title,
                title: 'View Comic',
                type: 'view',
                year_group
            }))}
        >
            <span>{c.title}<i className="icofont-arrow-up" /></span>
        </div>
        <div className="td issue_no number mobile">{c.issue_no}</div>
        <div className="td mobile" width="40">
            <i
                className="icofont-pencil-alt-5"
                onClick={() => dispatch(setModalType({
                    id: c.id,
                    comic_title: c.title,
                    title: 'Update Comic',
                    type: 'update',
                    year_group
                }))}
            >
            </i>
        </div>
    </div>;
};

export default ComicsRow;
