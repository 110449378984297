export const ADD_COMIC = "ADD_COMIC";
export const FETCH_BRANDS = "FETCH_BRANDS";
export const FETCH_COMIC = "FETCH_COMIC";
export const FETCH_COMICS = "FETCH_COMICS";
export const FETCH_RUNS = "FETCH_RUNS";
export const FETCH_TITLES = "FETCH_TITLES";
export const FETCH_WISHLIST = "FETCH_WISHLIST";
export const PREV_FETCH_VALS = "PREV_FETCH_VALS";
export const SET_MODAL_TYPE = "SET_MODAL_TYPE";
export const SET_SERIES_LIST = "SET_SERIES_LIST";
export const SET_TOAST = "SET_TOAST";
export const UPDATE_COMIC = "UPDATE_COMIC";
