import { initial } from "./initial";
import * as constants from "./constants";

let lastRow = {
  title: "",
  year: 0,
  issue_no: 0
};

const startNewRow = (comic) => {
  if (
    lastRow.title.trim() !== comic.title.trim() ||
    (lastRow.title.trim() === comic.title.trim() &&
      parseInt(comic.issue_no, 10) < parseInt(lastRow.issue_no, 10))
  ) {
    lastRow = {
      title: comic.title.trim(),
      year: comic.release_date,
      issue_no: comic.issue_no
    };
    return true;
  }

  lastRow = {
    ...lastRow,
    issue_no: comic.issue_no
  };
  return false;
};

const addByTitle = (comics, titles) => ({
  comics: titles.reduce((acc, curr) => {
    return acc[curr.title.trim()] = comics.body.filter(comic => comic.title.trim() === curr.title.trim()).reduce((a, v) => {
      if (!startNewRow(v)) {
        return {
          ...a,
          [lastRow.year]: [...a[lastRow.year], v]
        }
      } else {
        return {
          ...a,
          [lastRow.year]: [v]
        }
      }
    }, {})
      , acc
  }, {}),
  itemCount: comics.itemCount
});

export default (state = initial, action) => {
  switch (action.type) {

    case constants.ADD_COMIC: {
      if (
        !state.comics_all.comics[action.formData.title]
      ) {
        return {
          ...state,
          comics_all: {
            ...state.comics_all,
            comics: {
              ...state.comics_all.comics,
              [action.formData.title]: {
                [action.yearGroup]: [action.formData]
              }
            }
          }
        };
      }

      if (
        !state.comics_all.comics[action.formData.title][action.yearGroup]
      ) {
        return {
          ...state,
          comics_all: {
            ...state.comics_all,
            comics: {
              ...state.comics_all.comics,
              [action.formData.title]: {
                ...state.comics_all.comics[action.formData.title],
                [action.yearGroup]: [action.formData]
              }
            }
          }
        };
      }

      let comicIndex = state.comics_all.comics[action.formData.title][action.yearGroup].findIndex(c => parseInt(c.issue_no, 10) > parseInt(action.formData.issue_no, 10));

      if (comicIndex === -1) comicIndex = state.comics_all.comics[action.formData.title][action.yearGroup].length;

      return {
        ...state,
        comics_all: {
          ...state.comics_all,
          comics: {
            ...state.comics_all.comics,
            [action.formData.title]: {
              ...state.comics_all.comics[action.formData.title],
              [action.yearGroup]: [
                ...state.comics_all.comics[action.formData.title][action.yearGroup].slice(0, comicIndex),
                ...[action.formData],
                ...state.comics_all.comics[action.formData.title][action.yearGroup].slice(comicIndex)
              ]
            }
          }
        }
      };
    }

    case constants.FETCH_BRANDS:
      return {
        ...state,
        brands: action.brands.body
      };

    case constants.FETCH_COMIC:
      return {
        ...state,
        single: action.comic
      };

    case constants.FETCH_COMICS:
      return {
        ...state,
        comics_all: addByTitle(action.comics, state.titles),
        seriesName: action.seriesName,
        view: action.view
      };

    case constants.FETCH_RUNS:
      return {
        ...state,
        runs: action.runs.body
      };

    case constants.FETCH_WISHLIST:
      return {
        ...state,
        comics_wishlist: addByTitle(action.comics, state.titles),
        view: action.view
      };

    case constants.FETCH_TITLES:
      return {
        ...state,
        titles: action.titles.body
      };

    case constants.PREV_FETCH_VALS:
      return {
        ...state,
        prevFetchVals: action.vals
      }

    case constants.SET_MODAL_TYPE:
      return {
        ...state,
        modal: action.modalType
      };

    case constants.SET_SERIES_LIST:
      return {
        ...state,
        comics_series: action.comics,
        seriesName: action.seriesName,
        view: action.view
      }

    case constants.SET_TOAST:
      return {
        ...state,
        toast: action.text
      };

    case constants.UPDATE_COMIC: {
      if (
        !state.comics_all.comics[action.formData.title]
      ) {
        return {
          ...state,
          comics_all: {
            ...state.comics_all,
            comics: {
              ...state.comics_all.comics,
              [action.formData.title]: {
                [action.yearGroup]: [action.formData]
              }
            }
          }
        };
      }

      if (
        !state.comics_all.comics[action.formData.title][action.yearGroup]
      ) {
        return {
          ...state,
          comics_all: {
            ...state.comics_all,
            comics: {
              ...state.comics_all.comics,
              [action.formData.title]: {
                ...state.comics_all.comics[action.formData.title],
                [action.yearGroup]: [action.formData]
              }
            }
          }
        };
      }

      let comicIndex = state.comics_all.comics[action.formData.title][action.yearGroup].findIndex(c => parseInt(c.issue_no, 10) > parseInt(action.formData.issue_no, 10));

      if (comicIndex === -1) comicIndex = state.comics_all.comics[action.formData.title][action.yearGroup].length;

      return {
        ...state,
        comics_all: {
          ...state.comics_all,
          comics: {
            ...state.comics_all.comics,
            [action.formData.title]: {
              ...state.comics_all.comics[action.formData.title],
              [action.yearGroup]: [
                ...state.comics_all.comics[action.formData.title][action.yearGroup].slice(0, comicIndex - 1),
                ...[action.formData],
                ...state.comics_all.comics[action.formData.title][action.yearGroup].slice(comicIndex)
              ]
            }
          }
        }
      }
    };

    default:
      return state;
  }
};
