import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrands, fetchComic, fetchRuns, setModalType } from "../../../redux/actions";
import './index.css';

const formDataDefault = {
    brand: '',
    comic_status: '1',
    id: '',
    issue_no: '',
    purchase_price: '',
    release_date: '',
    reprint: '',
    series: '',
    title: '',
    variant_cover: '',
    volume: ''
};

const ComicView = ({
    modal
}) => {
    const dispatch = useDispatch();
    const brands = useSelector((state) => state.brands);
    const runs = useSelector((state) => state.runs);
    const single = useSelector((state) => state.single);
    const comics = modal.comic_title ? useSelector((state) => state.comics_all.comics) : null;
    const [formData, setFormData] = useState({
        ...formDataDefault,
        id: modal.id,
    });

    const getString = (num, list, key) => {
        if (list.length > 0) {
            const opts = list.filter(li => li.id.toString() === num.toString());
            return opts.length > 0 ? opts[0][key] : '';
        }
        return '';
    }

    useEffect(() => {
        if (modal.id && (!single || single.id !== modal.id)) {
            dispatch(fetchComic(modal.id));
            return;
        }
        if (modal.comic_title && comics) {
            // fetch comic to update
            let comic = null;
            const title_list = modal.year_group ? comics[modal.comic_title.trim()][`${modal.year_group}`] : [];

            if (title_list && title_list.length > 0) {
                comic = {
                    ...title_list[0],
                    comic_status: '1',
                    id: '',
                    issue_no: '',
                    purchase_price: '',
                    reprint: '',
                    series: '',
                    variant_cover: '',
                    volume: ''
                };
            }

            if (comic) {
                setFormData(comic);
            }
        }
    }, [modal.id]);

    useEffect(() => {
        if (modal.id && single && single.id === modal.id) {
            setFormData(single);
        }
    }, [single]);

    useEffect(() => {
        // on load check for brand/series
        if (!brands.length) {
            dispatch(fetchBrands());
        }

        if (!runs.length) {
            dispatch(fetchRuns());
        }
    }, []);

    return <>
        <section className="modal-content modal-rows">
            <h2>{formData.title} <span>{formData.issue_no}</span></h2>
            <div>
                <span>Publisher</span>
                <span>{getString(formData.brand, brands, 'name')}</span>
            </div>
            <div>
                <span>Volume</span> <span>{formData.volume}</span>
            </div>
            <div>
                <span>Released</span> <span>{formData.release_date}</span>
            </div>
            <div>
                <span>Price</span> <span>{formData.purchase_price}</span>
            </div>
            <div>
                <span className="checkable">Reprint</span>
                <span>{formData.reprint === "1" && <i className="icofont-check-circled" />}</span>
            </div>
            <div>
                <span className="checkable">Variant</span>
                <span>{formData.variant_cover === "1" && <i className="icofont-check-circled" />}</span>
            </div>
            <div>
                <span className="checkable">Wishlist</span>
                <span>{formData.comic_status === "0" && <i className="icofont-check-circled" />}</span>
            </div>
            <div>
                <span>Series</span> <span>{getString(formData.series, runs, 'title')}</span>
            </div>
            <div>
                <span>Order</span> <span>{formData.series_order}</span>
            </div>
        </section>
        <footer className="footer-view">
            <button className="pull-right" onClick={() => dispatch(setModalType({
                id: formData.id,
                comic_title: formData.title,
                title: 'Update Comic',
                type: 'update',
                year_group: modal.year_group
            }))}>Update Comic</button>
        </footer>
    </>;
};

export default ComicView;
